import axios from "axios";
import { lzNotifyErrorMessage } from "../../commons/notifications";
import { config } from "./config";

function HandleHTTPStatusCode(statusCode, result = {}, newCallBacks = {}, regardless = function () { }, forSpecificCodes = { statusCodes: [], callback_function: function () { } }) {
  let callBacks = {
    200: (result) => {
      alert("Ok");
      console.log(result);
    },
    201: (result) => {
      alert("Created");
      console.log(result);
    },
    202: (result) => {
      alert("Accepted");
      console.log(result);
    },
    301: (result) => {
      alert("Moved Permanently");
      console.log(result);
    },
    308: (result) => {
      alert("Permanent Redirect");
      console.log(result);
    },
    400: (result) => {
      lzNotifyErrorMessage("Bad Request");
      console.log(result);
    },
    401: (result) => {
      lzNotifyErrorMessage("Signin to continue");
      console.log(result);
    },
    403: (result) => {
      lzNotifyErrorMessage("Forbidden");
      console.log(result);
    },
    404: (result) => {
      lzNotifyErrorMessage("404 not found");
      console.log(result);
    },
    405: (result) => {
      lzNotifyErrorMessage("Method not allowed");
      console.log(result);
    },
    408: (result) => {
      lzNotifyErrorMessage("Request Timeout");
      console.log(result);
    },
    422: (result) => {
      lzNotifyErrorMessage("Unprocessable Entity");
      console.log(result);
    },
    429: (result) => {
      lzNotifyErrorMessage("Too Many Requests");
      console.log(result);
    },
    500: (result) => {
      lzNotifyErrorMessage("Internal server error");
      console.log(result);
    },
    502: (result) => {
      lzNotifyErrorMessage("Bad Gateway");
      console.log(result);
    },
    503: (result) => {
      lzNotifyErrorMessage("Service Unavailable");
      console.log(result);
    },
    504: (result) => {
      lzNotifyErrorMessage("Gateway Timeout");
      console.log(result);
    },
    undefined: (result) => {
      console.log(result);
      lzNotifyErrorMessage("Oops something didnot go as planned");
    }
  }
  callBacks = { ...callBacks, ...newCallBacks };
  (callBacks[statusCode])(result);

  regardless();
}

export default class AxiosHelper {
  http_get = (settings, HttpStatusCodeCallBacks = {}, regardless = function () { }) => {

    const method = 'get';
    const requestConfig = { ...config, ...settings, method };
    axios(requestConfig)
      .then((result) => { HandleHTTPStatusCode(result.status, result.data, HttpStatusCodeCallBacks, regardless) })
      .catch((error) => { console.log('ERROR', error); if (error.response) { HandleHTTPStatusCode(error.response.status, error.response.data, HttpStatusCodeCallBacks, regardless) } else { HandleHTTPStatusCode(undefined, {}, HttpStatusCodeCallBacks, regardless) } })
      .then(function () { });
  }

  http_post = (settings, HttpStatusCodeCallBacks = {}, regardless = function () { }) => {

    const method = 'POST';
    const requestConfig = { ...config, ...settings, method };
    axios(requestConfig)
      .then((result) => { HandleHTTPStatusCode(result.status, result.data, HttpStatusCodeCallBacks, regardless) })
      .catch((error) => { if (error.response) { HandleHTTPStatusCode(error.response.status, error.response.data, HttpStatusCodeCallBacks, regardless) } else { HandleHTTPStatusCode(undefined, {}, HttpStatusCodeCallBacks, regardless) } })
      .then(function () { });
  }

  http_put = (settings, HttpStatusCodeCallBacks = {}, regardless = function () { }) => {
    const method = 'PUT';
    const requestConfig = { ...config, ...settings, method };
    axios(requestConfig)
      .then((result) => { HandleHTTPStatusCode(result.status, result.data, HttpStatusCodeCallBacks, regardless) })
      .catch((error) => { if (error.response) { HandleHTTPStatusCode(error.response.status, error.response.data, HttpStatusCodeCallBacks, regardless) } else { HandleHTTPStatusCode(undefined, {}, HttpStatusCodeCallBacks, regardless) } })
      .then(function () { });
  }

  http_delete = (settings, HttpStatusCodeCallBacks = {}, regardless = function () { }) => {
    const method = 'DELETE';
    const requestConfig = { ...config, ...settings, method };
    axios(requestConfig)
      .then((result) => { HandleHTTPStatusCode(result.status, result.data, HttpStatusCodeCallBacks, regardless) })
      .catch((error) => { if (error.response) { HandleHTTPStatusCode(error.response.status, error.response.data, HttpStatusCodeCallBacks, regardless) } else { HandleHTTPStatusCode(undefined, {}, HttpStatusCodeCallBacks, regardless) } })
      .then(function () { });
  }
}

export class AxiosMultiRequestHelper {
  constructor(requests, successCallback = function () { }, failureCallback = function () { }, regardless = function () { }) {
    this.httpHelper = new AxiosHelper();
    this.requests = requests;
    this.successCallback = successCallback;
    this.failureCallback = failureCallback;
    this.regardless = regardless;
  }

  hit = () => {
    const requests = this.requests;
    let hits = [];
    for (let i = 0; i < requests.length; i++) {
      const { method, settings } = requests[i];
      const requestConfig = { ...config, ...settings, method };
      hits.push(axios(requestConfig));
    }

    axios.all(hits).then(
      axios.spread(
        (...responses) => { this.successCallback(responses); this.regardless(); }
      )
    ).catch(errors => { this.failureCallback(errors); this.regardless(); });
  }

}
