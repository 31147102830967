import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { wtApiCall } from '../../../helpers/commons/ApiWrapper'
import { wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage'
import { lzNotifyErrorMessageFromResult } from '../../../helpers/commons/notifications'
import { currentDateTime } from '../../../helpers/commons/moment'
import '../login/login.css'
import Loading from "../../../helpers/commons/loader";
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import { useDispatch } from 'react-redux'

const registerModel = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  city: '',
  renting_or_buying: '',
};
const ClientRegister = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [model, setModel] = useState(registerModel)
  const navigate = useNavigate()
  const register = (e) => {
    e.preventDefault()
    setIsLoadingLogin(true)
    wtApiCall(
      'client-register',
      model,
      'post',
      (result) => {
        alert(result.data);
        setIsLoadingLogin(false)
        setModel(registerModel)
      },
      (error) => {
        alert(error.message);
        setIsLoadingLogin(false)
      },
    )
  }

  const handleKeyUp = (key, event) => {
    if (key === 'email') {
      setEmail(event.target.value)
    }
    if (key === 'password') {
      setPassword(event.target.value)
    }
  }

  const changeValues = (key, value) => {
    const record = { ...model }
    record[key] = value
    setModel(record)
  }
  return (
    <CRow style={{ height: '100%', width: '99%' }} className="main-div">
      <CCol
        md="8"
        lg="8"
        className={'NotForMobile loginLeftPanel'}
        style={{ background: 'url("assets/background.jpg")' }}
      >
        {/* <h4>Nice To see you again</h4>
        <h1>WELCOME BACK</h1> */}
      </CCol>
      <CCol md={4}>
        <CCardGroup>
          <CCard className="p-4">
            <img src={'assets/kls_logo.jpg'} alt="KLS Logo" style={{ width: '70%' }} />
            <CCardBody>
              <CForm onSubmit={register}>
                <h1>Client Registeration</h1>
                <p className="text-medium-emphasis">Create a new account</p>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    value={model.first_name}
                    onChange={(event) => changeValues('first_name', event.target.value)}
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    value={model.last_name}
                    onChange={(event) => changeValues('last_name', event.target.value)}
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    className="form-control"
                    type="text"
                    placeholder="Phone"
                    value={model.phone}
                    onChange={(event) => changeValues('phone', event.target.value)}
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <select
                    className="form-control"
                    name="renting_or_buying"
                    value={model.renting_or_buying}
                    onChange={(event) => changeValues('renting_or_buying', event.target.value)}
                  >
                    <option value="" disabled selected>Renting or buying?</option>
                    <option value="Renting">Renting</option>
                    <option value="Buying">Buying</option>
                  </select>
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    className="form-control"
                    type="text"
                    placeholder="City you wish to relocate?"
                    value={model.city}
                    onChange={(event) => changeValues('city', event.target.value)}
                  />
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    autoComplete="username"
                    value={model.email}
                    onChange={(event) => changeValues('email', event.target.value)}
                  />
                </CInputGroup>

                <CRow>
                  <CCol xs={6}>
                    {isLoadingLogin ? (
                      Loading
                    ) : (
                      <CButton color="primary" className="px-4" type="submit">
                        Client Register
                      </CButton>
                    )}
                  </CCol>
                  <CCol xs={6} className="text-right">
                    <span>Already Registered?</span>&nbsp;<Link to={'/'}>Login</Link>
                  </CCol>
                  {/* <CCol xs={6} className="text-right">
                    <CButton color="link" className="px-0">
                      Forgot password?
                    </CButton>
                  </CCol> */}
                </CRow>

              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  )
}

export default ClientRegister
