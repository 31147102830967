import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CInputGroup,
  CModalBody,
  CRow,
} from '@coreui/react'
import { wtApiCall } from '../../helpers/commons/ApiWrapper'
import { APP_BASE_URL } from '../../config/Request'
import Loading from 'src/helpers/commons/loader'

export default function ViewProperty() {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const token = searchParams.get('token')
  const params = useParams()
  const [data, setData] = useState(null)

  const getData = () => {
    wtApiCall(
      'view-property/' + id,
      {
        token: token ? token : ''
      },
      'get',
      (response) => {
        setData(response.data)
      },
      (error) => {
        alert(error.message)
      },
    )
  }
  useEffect(() => {
    getData()
  }, [])

  if (data === null) {
    return Loading
  }
  return (
    <div>
      <div
        style={{
          backgroundColor: '#f8f8f8',
          padding: '2rem 1rem 4rem 1rem',
          borderRadius: '14px',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }}
      >
        <div
          className="mDIv"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="firstDiv" style={{ display: 'inline', padding: '.2rem' }}></div>

          <div className="secondDIv" style={{ display: 'inline', padding: '.2rem' }}>
            {/* <CButton className="mb-1" color={"primary"} onClick={onGoToList}>
            Go to List
          </CButton> */}
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#fff',
            padding: '2rem 1rem',
            borderRadius: '10px',
          }}
        >
          <CModalBody>
            <CRow>
              <CCol md="5">
                <img
                  src={data ? APP_BASE_URL + data.property.featured_image : '#'}
                  alt="Block of Flats Image"
                  style={{ width: '50%' }}
                ></img>
              </CCol>

              <CCol md="7" style={{ marginBottom: '20px' }}>
                <label>
                  <b style={{ fontSize: '18px' }}>Small Description About Property:</b>
                </label>
                <CCard className="card-dark">
                  <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                    <CForm>
                      <CRow>
                        <CCol md="12">
                          <CInputGroup style={{ color: 'black' }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data ? data.property.short_description : '',
                              }}
                            ></div>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CRow>
                  <CCol md="4">
                    <label>City, County:&nbsp;{data ? data.property.city_county : ''}</label>
                  </CCol>
                  <CCol md="4"></CCol>
                  <CCol md="4">
                    <label>List Price:&nbsp;{data ? data.property.original_price : ''}</label>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" style={{ marginBottom: '20px' }}>
                <label>
                  <b style={{ fontSize: '18px' }}>Remarks:</b>
                </label>
                <CCard className="card-dark">
                  <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                    <CForm>
                      <CRow>
                        <CCol md="12">
                          <CInputGroup>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data ? data.property.long_description : '',
                              }}
                            ></div>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" style={{ marginBottom: '20px' }}>
                <label>
                  <b style={{ fontSize: '18px' }}>Property Information:</b>
                </label>
                <CCard className="card-dark">
                  <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                    <CForm>
                      <CRow>
                        <CCol md="4" style={{ marginBottom: '10px' }}>
                          <CInputGroup>
                            <label>
                              <b>Year Established:</b>
                              &nbsp;
                              {data ? data.property.year_established : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>SQ Meter:</b>&nbsp;
                              {data ? data.property.sq_meter : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>DSQ:</b>&nbsp;
                              {data && data.property.dsq === 1 ? 'Yes' : 'No'}
                            </label>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="4" style={{ marginBottom: '10px' }}>
                          <CInputGroup>
                            <label>
                              <b>bedrooms: </b>&nbsp;
                              {data ? data.property.bed_rooms : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>Restrooms: </b>
                              &nbsp;{data ? data.property.rest_rooms : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4" style={{ marginBottom: '10px' }}>
                          <CInputGroup>
                            <label>
                              <b>Parking Space: </b>
                              &nbsp;
                              {data ? data.property.parking_space : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>Disclosures: </b>
                              &nbsp;{data ? data.property.disclosure : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol md="12">
                <label>
                  <b style={{ fontSize: '18px' }}>Features:</b>
                </label>
                <CCard className="card-dark">
                  <CCardBody style={{ backgroundColor: '#f7f9fa' }}>
                    <CForm>
                      <CRow>
                        <CCol md="4" style={{ marginBottom: '10px' }}>
                          <CInputGroup>
                            <label>
                              <b>Full Fitted Kitchen:</b>
                              &nbsp;
                              {data && data.property.fitted_kitchen === 1 ? 'Yes' : 'No'}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>Amenities:</b>&nbsp;
                              {data.property.amenities ? data.property.amenities : '-'}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4" style={{ marginBottom: '10px' }}>
                          <CInputGroup>
                            <label>
                              <b>Neighbourhood:</b>
                              &nbsp; {data ? data.property.neighborhood_one : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>Tenant Expenses:</b>
                              &nbsp;
                              {data
                                ? data.property_expenses.map(
                                  (item) => item.property_expense.expense_name,
                                ) + ','
                                : ''}
                            </label>
                          </CInputGroup>
                        </CCol>
                        <CCol md="4">
                          <CInputGroup>
                            <label>
                              <b>Backup Generator:</b>
                              &nbsp;
                              {data && data.property.backup_generator === 1 ? 'Yes' : 'No'}
                            </label>
                          </CInputGroup>
                        </CCol>

                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol md="12" style={{ marginBottom: '20px' }}>
                <label>
                  <b style={{ color: "black", fontSize: "18px" }}>Features:</b>
                </label>
                <CCard className="card-dark">
                  <CCardBody style={{ backgroundColor: "#f7f9fa" }}>
                    <CForm>
                      <CRow>
                        <CCard>
                          <CCardBody>
                            {data
                              ? data.property_images.map((item, index) => (
                                <CCol md="12" style={{ marginBottom: '10px' }}>
                                  <img
                                    src={APP_BASE_URL + item.property_image}
                                    alt={`Property Image`}
                                    key={index}
                                    style={{ width: "50%" }}
                                  />
                                </CCol>
                              ))
                              : ""}
                          </CCardBody>
                        </CCard>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CModalBody>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            {/* <CButton color={"primary"} className="my-2" onClick={() => save()}>
            Save
          </CButton> */}
          </div>
        </div>
      </div>
    </div>
  )
}
