export const wtHrefHashSetVars = (vars) => {
  let hash = '';

  if (vars) {
    const keys = Object.keys(vars);
    for (const key of keys) {
      if ('' === hash) {
        hash += '#' + key + ':' + vars[key];
      } else {
        hash += '~' + key + ':' + vars[key];
      }
    }
  }

  window.location.hash = hash;
};

export const wtHrefHashGetVars = () => {
  const hash = window.location.hash.substr(1);
  const out = {};

  const parts1 = hash.split('~');
  for (const part1 of parts1) {
    const parts2 = part1.split(':', 2);
    out[parts2[0]] = parts2[1];
  }

  return out;
};
