import { API_BASE_URL, APP_BASE_URL, FRONT_BASE_URL } from "../../../config/Request";

/**
 * This object holds the App related global constants.
 *
 * @type object
 */
export const WtAppConstants = {
  app_code: 'gcsERPAdmin',
  app_name: 'ERP Admin',
  app_base_url: process.env.REACT_APP_BACK_BASE_URL || APP_BASE_URL,
  api_base_url: process.env.REACT_APP_API_BASE_URL || API_BASE_URL,
  front_base_url: process.env.REACT_APP_FRONT_BASE_URL || FRONT_BASE_URL,
};
