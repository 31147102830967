import React, { useEffect, Suspense, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react'
import { wtLoadLoggedInData } from 'src/helpers/commons/utils'
import { wtGetFromLocalStorage } from 'src/helpers/commons/LocalStorage'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import { wtHrefHashGetVars } from 'src/helpers/commons/HrefHash'
import { lzNotifyErrorMessageFromResult } from 'src/helpers/commons/notifications'

export default function Reports({ authToken, authUser }) {
    const [reportCode, setReportCode] = useState("");
    const [reportParams, setReportParams] = useState("");
    const errorMessages = useSelector((state) => state.errorMessages)
    const successMessages = useSelector((state) => state.successMessages)

    const discardErrorMsg = (dex) => {
        const reduxDispatch = window.reduxStore.dispatch
        const msgs = errorMessages.slice()
        msgs.splice(dex, 1)
        reduxDispatch({ type: 'set', errorMessages: msgs })
    }

    const discardSuccessMsg = (dex) => {
        const reduxDispatch = window.reduxStore.dispatch

        const msgs = successMessages.slice()
        msgs.splice(dex, 1)
        reduxDispatch({ type: 'set', successMessages: msgs })
    }

    const loadReport = (params) => {
        setReportCode(params.code)
        setReportParams(params)
    }
    useEffect(() => {
        //call logged in api again to update user (user rights)
        const authToken = wtGetFromLocalStorage('authToken', '');
        if (authToken !== null && authToken !== '' && typeof authToken !== 'undefined') {
            wtLoadLoggedInData();
        }
        // Get hash params
        const hashParams = wtHrefHashGetVars();

        // Check if code parameter is passed or not
        if (!hashParams.hasOwnProperty("code")) {
            lzNotifyErrorMessageFromResult("Invalid code provided");
            return;
        }

        loadReport(hashParams);
    }, [])
    return (
        <div>
            <CToaster placement="top-end">
                {errorMessages.length > 0 &&
                    errorMessages.map((eMsg, eDex) => (
                        <CToast
                            animation={true}
                            autohide={true}
                            visible={true}
                            key={eDex}
                            color="danger"
                            onClick={() => discardErrorMsg(eDex)}
                        >
                            <CToastBody className="text-white">
                                <CToastClose className="me-2 m-auto" white style={{ float: 'right' }} />
                                <h4>
                                    <strong>Error!</strong>
                                </h4>
                                {eMsg}
                            </CToastBody>
                        </CToast>
                    ))}

                {successMessages.length > 0 &&
                    successMessages.map((eMsg, eDex) => (
                        <CToast key={eDex} animation={true} autohide={true} visible={true} color="success">
                            <CToastBody className="text-white">
                                <CToastClose
                                    className="me-2 m-auto"
                                    white
                                    style={{ float: 'right' }}
                                    onClick={() => discardSuccessMsg(eDex)}
                                />
                                <h4>
                                    <strong>Success!</strong>
                                </h4>
                                {eMsg}
                            </CToastBody>
                        </CToast>
                    ))}
            </CToaster>
            {authToken !== "" && authToken !== null ? (
                <>
                   //
                </>
            ) : (
                <Navigate to={'/login'} />
            )}
        </div>
    )
}
