import AxiosHelper from "./AxiosHelper";
import {AxiosMultiRequestHelper} from "./AxiosHelper";

export default class HttpHelper extends AxiosHelper {
}

export class MultiRequestHttpHelper extends AxiosMultiRequestHelper {

    constructor(requests, successCallback = function() {}, failureCallback = function() {}, regardless = function() {}) {
        super(requests, successCallback, failureCallback, regardless);
    }
}
