import { wtParseApiCallFailureMessage } from "./ApiWrapper";
// import toastr from "toastr";

export const lzNotifyErrorMessage = (msg) => {
  // Get state and dispatch from redux store
  const reduxState = window.reduxStore ? window.reduxStore.getState() : null;
  const reduxDispatch = window.reduxStore.dispatch;

  // Add the msg to the stack
  const msgs = reduxState.errorMessages ? reduxState.errorMessages.slice() : [];
  msgs.push(msg);

  // Set the error msg
  reduxDispatch({ type: 'set', errorMessages: msgs });
}

export const lzNotifySuccessMessage = (msg) => {
  // Get state and dispatch from redux store
  const reduxState = window.reduxStore ? window.reduxStore.getState() : null;
  const reduxDispatch = window.reduxStore.dispatch;

  // Add the msg to the stack
  const msgs = reduxState.successMessages ? reduxState.successMessages.slice() : [];
  msgs.push(msg);

  // Set the success msg
  reduxDispatch({ type: 'set', successMessages: msgs });

}

export const lzNotifyErrorMessageFromResult = (res) => {
  const msg = wtParseApiCallFailureMessage(res);
  if (msg !== '') {
    lzNotifyErrorMessage(msg);
  }
}
